<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template
            v-if="$permissionAbility(EXTRA_LEAVE_POLICY_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0 mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->

          <template v-if="props.column.field === 'format_title'">
            <b>{{ props.row.title }}</b>
          </template>

          <template v-if="props.column.field === 'format_salary_type'">
            <b-badge :variant="props.row.salary_type === 'basic' ? 'success' : 'primary'">{{ (props.row.salary_type).toUpperCase() }}</b-badge>
          </template>

          <template v-if="props.column.field === 'format_deduction_type'">
            <b-badge :variant="props.row.deduction_type === 'current_monthly_salary' ? 'success' : 'primary'">{{ props.row.deduction_type.toUpperCase().replace(/_/g, ' ') }}
            </b-badge>
          </template>

          <template v-if="props.column.field === 'format_salary_deduct_days'">
            <b-badge  variant="light-info">
              {{ props.row.extra_leave_days * props.row.salary_deduct_days }}
            </b-badge>
          </template>

          <template v-if="props.column.field === 'format_extra_leave_days'">
            <b-badge  variant="light-primary">
              {{ props.row.extra_leave_days }}
            </b-badge>
          </template>

          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template
                  v-if="
                    $permissionAbility(EXTRA_LEAVE_POLICY_EDIT, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <!-- <template
                  v-if="
                    $permissionAbility(EXTRA_LEAVE_POLICY_EDIT, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onShowPage(props.row)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Show</span>
                  </b-dropdown-item>
                </template> -->

                <template
                  v-if="
                    $permissionAbility(
                      EXTRA_LEAVE_POLICY_DELETE,
                      permissions
                    )
                  "
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-extra-leave-policy-from"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Extra Leave Policy'
          : 'Create Extra Leave Policy'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="addextraLeavePolicy">
        <b-form v-on:submit.prevent="addextraLeavePolicyForm">
          <!-- name -->
          <b-form-group label="Policy" label-for="policy" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="policy"
              vid="title"
              rules="required|max:255"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter Policy Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <b-row>
             <b-col cols="9">
               <b-form-group
                 label="For how many days of extra leave:"
                 label-for="extra_leave_days_value"
                 class="required-label"
               >
                 <validation-provider
                     #default="{ errors }"
                     name="Extra leave days"
                     vid="days_value"
                     rules="required|min_value:1"
                 >
                   <b-form-input
                       id="extra_leave_days_value"
                       type="text"
                       v-model="extra_leave_days"
                       :state="errors.length > 0 ? false : null"
                       placeholder="Enter extra leave Days"
                   />

                   <small class="text-danger">{{ errors[0] }}</small>
                 </validation-provider>
               </b-form-group>
             </b-col>
             <b-col cols="3" class="align-content-center">Days</b-col>
           </b-row>

          <b-row>
             <b-col cols="9">
               <b-form-group
                   label="Salary deducted will be:"
                   label-for="salary_deduct_days_value"
                   class="required-label"
               >
                 <validation-provider
                     #default="{ errors }"
                     name="Salary deducted days"
                     vid="salary_deduct_days_value"
                     rules="required|min_value:1"
                 >
                   <b-form-input
                       id="salary_deduct_days_value"
                       type="text"
                       v-model="salary_deduct_days"
                       :state="errors.length > 0 ? false : null"
                       placeholder="Enter salary deducted Days"
                   />

                   <small class="text-danger">{{ errors[0] }}</small>
                 </validation-provider>
               </b-form-group>
             </b-col>
             <b-col cols="3" class="align-content-center">Days</b-col>
           </b-row>

          <b-form-group
              label="Salary Type "
              label-for="salary_type"
              class="required-label"
          >
            <ValidationProvider
                name="salary type"
                v-slot="{ errors }"
                vid="salary_type"
                rules="required"
            >
              <v-select
                id="salary_type"
                v-model="salaryType"
                :options="salaryTypeConstants"
                :reduce="(option) => option.value"
                label="name"
                placeholder="Choose Here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
              label="Deduction Type"
              label-for="deduction_type"
              class="required-label"
          >
            <ValidationProvider
                name="Deduction type"
                v-slot="{ errors }"
                vid="deduction_type"
                rules="required"
            >
              <v-select
                  id="deductionType"
                  v-model="deductionType"
                  :options="deductionTypeOptions"
                  :reduce="(option) => option.value"
                  label="name"
                  placeholder="Choose Here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isextraLeavePolicyLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

    <script>
    import {
      BCard,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BButton,
      BForm,
      BModal,
      BSpinner, VBTooltip, BCol, BRow,
    } from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import { salaryTypeConstants } from "@/helpers/constant/salaryTypeConstant";
import { LeavePolicyPolicyAmountValueTypeConstant } from "@/helpers/constant/ExtraLeavePolicyAmountValueTypeConstant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  EXTRA_LEAVE_POLICY_CREATE,
  EXTRA_LEAVE_POLICY_EDIT,
  EXTRA_LEAVE_POLICY_DELETE,
  EXTRA_LEAVE_POLICY_SHOW,
} from "@/helpers/permissionsConstant";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "extraLeavePolicyView",
  components: {
    BRow,
    BCol,
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      EXTRA_LEAVE_POLICY_CREATE,
      EXTRA_LEAVE_POLICY_EDIT,
      EXTRA_LEAVE_POLICY_DELETE,
      EXTRA_LEAVE_POLICY_SHOW,

      modelType: "",
      defaultCurrency: "",

      extraLeavePolicyId: "",
      salaryTypeConstants,
      salaryType: "",
      deductionType: "",
      deductionTypeOptions: [
        {
          name: 'Current Monthly Salary',
          value: 'current_monthly_salary',
        },
        {
          name: 'Monthly average salary of current year',
          value: 'monthly_average_salary',
        },
      ],
      filteredLeavePolicyPolicyAmountValueTypeConstant: [],
      LeavePolicyPolicyAmountValueTypeConstant,
      amountValueType: "",
      title: "",
      amount: "",
      extra_leave_days: "",
      salary_deduct_days: "",

      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Salary Type",
          field: "format_salary_type",
          sortable: false,
        },
        {
          label: "Deduction Type",
          field: "format_deduction_type",
          sortable: false,
        },
        {
          label: "Extra Leave Days",
          field: "format_extra_leave_days",
          sortable: false,
        },
        {
          label: "Salary deduct Days",
          field: "format_salary_deduct_days",
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
          sortable: true,
          formatFn: this.formatDate,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isextraLeavePolicyLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [getSetting, getCurrencies] =
          await Promise.all([
            this.getSetting({
              select:
                  "currency_id",
            }),
            this.getCurrencies(),
          ]);

      // getSetting
      const currencyId = getSetting?.data?.data?.currency_id;

      // getCurrencies
      const currencyData = getCurrencies?.data?.data?.filter((item) => {
        return item?.id === currencyId
      });

      this.defaultCurrency = currencyData?.[0]?.currency;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getCurrencies() {
      return await this.$api.get("/api/currencies/all");
    },
    // onShowPage(value) {
    //   let policyCategoryName = "extra-leave-policy";

    //   let policyCategoryId = value?.policy_category_id;
    //   let id = value?.id;
    //   this.$router.push({
    //     name: "admin-payroll-policy-assign-details",
    //     params: { policyCategoryName, policyCategoryId, id },
    //   });
    // },

    filterAmountType() {
      // const lastIndex = this.LeavePolicyPolicyAmountValueTypeConstant.length - 1;
      this.filteredLeavePolicyPolicyAmountValueTypeConstant =
        this.LeavePolicyPolicyAmountValueTypeConstant.slice(0, this.LeavePolicyPolicyAmountValueTypeConstant.length);
    },
    showModal() {
      this.filterAmountType();
      this.$bvModal.show("modal-extra-leave-policy-from");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-extra-leave-policy-from");
      this.resetModal();
    },
    resetModal() {
      this.extraLeavePolicyId = "";
      this.salaryType = "";
      this.title = "";
      this.extra_leave_days = "";
      this.salary_deduct_days = "";

    },
    async onShow(value) {
      this.modelType = "editModel";

      this.extraLeavePolicyId = value?.id;
      this.salaryType = value?.salary_type;
      this.amountValueType = value?.amount_value_type;
      this.title = value?.title;
      this.amount = value?.amount_value;
      this.extra_leave_days = value?.extra_leave_days;
      this.salary_deduct_days = value?.salary_deduct_days;
      this.deductionType = value?.deduction_type;


      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/extra-leave-policies/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    formatAmount(row) {
      if (row?.amount_value_type == "percentage") {
        return `${row?.amount_value} %`;
      }
      return row?.amount_value + " " + this.defaultCurrency;
    },
    formatAmountType(value) {
      const amountType = LeavePolicyPolicyAmountValueTypeConstant.find(
        (item) => item.value === value
      );
      return amountType?.name;
    },

    formatSalaryType(value) {
      const salaryType = salaryTypeConstants.find(
        (item) => item.value === value
      );
      return salaryType?.name;
    },
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getextraLeavePolicies(params) {
      return await this.$api.get("api/extra-leave-policies", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async getUsers() {
      return await this.$api.get("api/users/all?include=designation");
    },

    async loadItems() {
      try {
        const extraLeavePolicies = await this.getextraLeavePolicies({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = extraLeavePolicies?.data?.data;

        const meta = extraLeavePolicies?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    addextraLeavePolicyForm: async function () {
      this.$refs.addextraLeavePolicy.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isextraLeavePolicyLoading = true;
              await this.$api.put(
                `/api/extra-leave-policies/${this.extraLeavePolicyId}`,

                {
                  title: this.title,
                  salary_type: this.salaryType,
                  extra_leave_days: this.extra_leave_days,
                  salary_deduct_days: this.salary_deduct_days,
                  deduction_type: this.deductionType,
                },
              );
              this.isextraLeavePolicyLoading = false;
              this.hiddenModal();
              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Extra Leave Policy Successfully Updated",
                },
              });
            } else {
              this.isextraLeavePolicyLoading = true;

              await this.$api.post(
                "/api/extra-leave-policies",

                {
                  title: this.title,
                  salary_type: this.salaryType,
                  extra_leave_days: this.extra_leave_days,
                  salary_deduct_days: this.salary_deduct_days,
                  deduction_type: this.deductionType,
                },
              )
              this.isextraLeavePolicyLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Extra Leave Policy Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isextraLeavePolicyLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.addextraLeavePolicy.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
