var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _vm.$permissionAbility(_vm.EXTRA_LEAVE_POLICY_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'format_title' ? [_c('b', [_vm._v(_vm._s(props.row.title))])] : _vm._e(), props.column.field === 'format_salary_type' ? [_c('b-badge', {
          attrs: {
            "variant": props.row.salary_type === 'basic' ? 'success' : 'primary'
          }
        }, [_vm._v(_vm._s(props.row.salary_type.toUpperCase()))])] : _vm._e(), props.column.field === 'format_deduction_type' ? [_c('b-badge', {
          attrs: {
            "variant": props.row.deduction_type === 'current_monthly_salary' ? 'success' : 'primary'
          }
        }, [_vm._v(_vm._s(props.row.deduction_type.toUpperCase().replace(/_/g, ' ')) + " ")])] : _vm._e(), props.column.field === 'format_salary_deduct_days' ? [_c('b-badge', {
          attrs: {
            "variant": "light-info"
          }
        }, [_vm._v(" " + _vm._s(props.row.extra_leave_days * props.row.salary_deduct_days) + " ")])] : _vm._e(), props.column.field === 'format_extra_leave_days' ? [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props.row.extra_leave_days) + " ")])] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.EXTRA_LEAVE_POLICY_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.EXTRA_LEAVE_POLICY_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-extra-leave-policy-from",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Extra Leave Policy' : 'Create Extra Leave Policy',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "addextraLeavePolicy"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addextraLeavePolicyForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Policy",
      "label-for": "policy"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "policy",
      "vid": "title",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Policy Title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "For how many days of extra leave:",
      "label-for": "extra_leave_days_value"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Extra leave days",
      "vid": "days_value",
      "rules": "required|min_value:1"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "extra_leave_days_value",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter extra leave Days"
          },
          model: {
            value: _vm.extra_leave_days,
            callback: function callback($$v) {
              _vm.extra_leave_days = $$v;
            },
            expression: "extra_leave_days"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-content-center",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Days")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Salary deducted will be:",
      "label-for": "salary_deduct_days_value"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Salary deducted days",
      "vid": "salary_deduct_days_value",
      "rules": "required|min_value:1"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "salary_deduct_days_value",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter salary deducted Days"
          },
          model: {
            value: _vm.salary_deduct_days,
            callback: function callback($$v) {
              _vm.salary_deduct_days = $$v;
            },
            expression: "salary_deduct_days"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-content-center",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Days")])], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Salary Type ",
      "label-for": "salary_type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "salary type",
      "vid": "salary_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "salary_type",
            "options": _vm.salaryTypeConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.salaryType,
            callback: function callback($$v) {
              _vm.salaryType = $$v;
            },
            expression: "salaryType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Deduction Type",
      "label-for": "deduction_type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Deduction type",
      "vid": "deduction_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "id": "deductionType",
            "options": _vm.deductionTypeOptions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.deductionType,
            callback: function callback($$v) {
              _vm.deductionType = $$v;
            },
            expression: "deductionType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isextraLeavePolicyLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }